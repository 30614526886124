import type { Ref } from 'vue'

import type { GetFlashMessagesQuery } from '~/App.generated'
import type { MessageType } from '~/types/graphql'

import { ref, watch } from 'vue'
import { MessageTypeEnum } from '~/types/graphql'

interface ToastMessage extends MessageType {
  permanent: boolean
}

const removeGQLPrefix = (m: string) => m.replace('[GraphQL] ', '')
export const toastMessages = ref<ToastMessage[]>([])
export function addToast(message: string, type: MessageTypeEnum = MessageTypeEnum.Info, permanent = false): void {
  toastMessages.value.unshift({
    message: removeGQLPrefix(message),
    type,
    permanent,
  })

  if (!permanent)
    window.setTimeout(() => toastMessages.value.pop(), 4000)
}

export function useFlashMessages(data: Ref<GetFlashMessagesQuery | null>): void {
  watch(
    () => data.value?.flashMessages,
    (messages) => {
      if (messages) {
        for (const message of messages)
          addToast(message.message, message.type)
      }
    },
  )
}
