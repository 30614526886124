// Taken from MDN
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
function isStorageAvailable(type: 'localStorage' | 'sessionStorage') {
  let storage
  try {
    storage = window[type]
    const x = '__storage_test__'
    window.localStorage.setItem(x, x)
    window.localStorage.removeItem(x)
    return true
  }
  catch (e) {
    return (
      e instanceof DOMException
      // everything except Firefox
      && (e.code === 22
      // Firefox
        || e.code === 1014
      // test name field too, because code might not be present
      // everything except Firefox
        || e.name === 'QuotaExceededError'
      // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      // acknowledge QuotaExceededError only if there's something already stored
      && storage
      && storage.length !== 0
    )
  }
}

export default class SafeLocalStorage {
  static storageAvailable() {
    return isStorageAvailable('localStorage')
  }

  static getItem(key: string) {
    if (isStorageAvailable('localStorage'))
      return window.localStorage?.getItem(key)
  }

  static setItem(key: string, value: string) {
    if (isStorageAvailable('localStorage'))
      window.localStorage?.setItem(key, value)
  }

  static removeItem(key: string) {
    if (isStorageAvailable('localStorage'))
      window.localStorage?.removeItem(key)
  }
}

export class SafeSessionStorage {
  static storageAvailable() {
    return isStorageAvailable('localStorage')
  }

  static getItem(key: string) {
    if (isStorageAvailable('sessionStorage'))
      return window.sessionStorage?.getItem(key)
  }

  static setItem(key: string, value: string) {
    if (isStorageAvailable('sessionStorage'))
      window.sessionStorage?.setItem(key, value)
  }

  static removeItem(key: string) {
    if (isStorageAvailable('sessionStorage'))
      window.sessionStorage?.removeItem(key)
  }
}
