<script setup lang="ts">
import type { TagMapping } from '~/pages/automations/tools/tag-mapping'

import { computed, toRef } from 'vue'

import { useToolInstallationCount } from '~/composables/automations'

const props = defineProps<{
  tool: {
    name: string
    slug: string
    tags: TagMapping[]
    toolType?: TagMapping
    isRecommended?: boolean
    installationCount: number
    linkedFeatureUrl?: string | null
    thumbnailUrl?: string | null
  }
}>()

const installationCount = useToolInstallationCount(
  toRef(() => ({
    installationCount: props.tool.installationCount,
    linkedFeatureUrl: props.tool.linkedFeatureUrl,
  })),
)

const tagList = computed(() =>
  props.tool.tags.filter(tag => tag.type !== props.tool.toolType?.type).slice(0, 3),
)

const remainingTags = computed(() => props.tool.tags.length - 3)
</script>

<template>
  <div class="tw-min-w-0 tw-pr-3">
    <span
      v-for="tag in tagList"
      :key="tag.tag"
      class="tw-mr-1 tw-inline-block tw-whitespace-nowrap tw-rounded-md tw-bg-gray-200 tw-px-1.5 tw-py-0.5 tw-text-xxs tw-font-semibold tw-text-gray-500 dark:tw-bg-gray-700 dark:tw-text-gray-300"
    >
      {{ tag.label }}
    </span>
    <span
      v-if="remainingTags > 0"
      class="tw-mr-1 tw-inline-block tw-whitespace-nowrap tw-rounded-md tw-bg-gray-200 tw-px-1.5 tw-py-0.5 tw-text-xxs tw-font-semibold tw-text-gray-500 dark:tw-bg-gray-700 dark:tw-text-gray-300"
    >
      +{{ remainingTags }}
    </span>
  </div>
  <span
    v-if="installationCount"
    class="tw-ml-auto tw-mt-1 tw-min-w-0 tw-flex-shrink-0 tw-text-xs tw-text-gray-400"
  >{{ installationCount }} installs</span>
</template>
