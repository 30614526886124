<script lang="ts" setup>
import { computed } from 'vue'

import CubesIcon from '~icons/light/cubes'

import PennantIcon from '~icons/light/flag-pennant'
import TypeWriterIcon from '~icons/light/typewriter'
import { ChangeSourceType } from '~/types/graphql'

const props = defineProps<{ type: ChangeSourceType }>()

const icon = computed(
  () =>
    ({
      [ChangeSourceType.Code]: CubesIcon,
      [ChangeSourceType.FeatureFlag]: PennantIcon,
      [ChangeSourceType.Manual]: TypeWriterIcon,
    })[props.type],
)
</script>

<template>
  <component :is="icon" />
</template>
